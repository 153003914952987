<template>
    <div style="background-color: #fff">
        <div class="mt-3 pt-3">
            <search @condition="condition" :fields="searchFields">
                <el-button @click="addAd"  v-access="{url:baseUrl,method:'POST'}" size="medium" slot="add" icon="el-icon-plus" type="primary">添加广告配置</el-button>
            </search>
        </div>
        <el-button-group  class="mt-3 ml-2">
            <el-button size="mini" plain @click="changeType(item.name,index)" :key="item.name" v-for="(item,index) in adConfig.type" :type="item.name===adConfig.active?'primary':'default'">{{item.title}}</el-button>
        </el-button-group>
        <div style="height: 50px;" class="mr-3 text-right">
            <div v-show="ad_optimize_loading_ok">
                <span style="font-size: 15px" class="mr-3">广告优化配置：</span>
                <el-switch
                    @change="adOptimizeChange"
                    v-model="ad_optimize"
                    active-text="启用优化"
                    inactive-text="关闭优化">
                </el-switch>
                <el-link class="ml-3" type="primary" icon="el-icon-edit" @click="editElseConfig">其它配置</el-link>
            </div>

        </div>
        <div style="height: 50px;" class="mt-3 mr-3 text-right">
            <div v-show="qq.show">
                <span style="font-size: 15px" class="mr-3">QQ加群Key：</span>
                <el-link type="default" @click="editQq">{{ qq.key }}</el-link>
                <input type="file" accept=".html,.htm" @change="copyChange" id="copy-file">
                <el-button size="mini" round class="ml-3" @click="uploadCopy" type="primary" icon="el-icon-upload">上传App
                    CopyRight
                </el-button>
                <a class="ml-3" style="font-size: 14px" :href="'http://m.bigemap.com/app/copyright.html?_tt='+Math.random()" target="_blank">查看</a>
            </div>
        </div>
        <div class="mt-3">
            <my-table :data="tableData" :fields="showFields">
                <el-table-column slot="operation" fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="editAd(scope.row._index)" v-access="{url:baseUrl,method:'PUT'}"
                            icon="el-icon-edit"></el-button>
                        <!--                        <el-button-->
                        <!--                            size="mini"-->
                        <!--                            type="danger"-->
                        <!--                            @click="deleteServer(scope.$index,scope.row.id)" v-access="{url:baseUrl,method:'DELETE'}" icon="el-icon-delete"></el-button>-->
                    </template>
                </el-table-column>
            </my-table>
        </div>
<!--        <div class="pb-3 mt-3">-->
<!--            <el-pagination-->
<!--                @size-change="sizeChange"-->
<!--                @current-change="pageChange"-->
<!--                :current-page="page.pageNumber"-->
<!--                :page-sizes="[10, 15, 30, 50]"-->
<!--                :page-size="page.pageSize"-->
<!--                :background="true"-->
<!--                layout="sizes,prev, pager,next,total"-->
<!--                :total="page.total">-->
<!--            </el-pagination>-->
<!--        </div>-->
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import search from "@/components/common/search";
import MyTable from "@/components/common/my-table";
import axiox from 'axios';

export default {
    name: "appAd",
    components: {
        search, MyTable
    },
    created() {

    },
    mounted() {

    },
    data: function () {
        return {
            baseUrl: '/manage/app/ad',
            singleConfigUrl: '/manage/app/singleConfig',
            // updateUrl: '/manage/app/ad',
            ad_optimize: true,
            //广告的优化只有在加载完成之后，才会显示出来
            ad_optimize_loading_ok: false,
            page: {
                data:[],
                search: {},
                sort: {},
                total: 1,
                pageSize: 15,
                pageNumber: 1,
            },
            adConfig:{
                type:[
                    {
                        name:'android',
                        title:'Android配置'
                    },
                    {
                        name:'ios',
                        title:'IOS配置'
                    },
                    {
                        name:'all',
                        title:'旧配置'
                    },
                ],
                else:{

                },
                index:0,
                active:''
            },
            qq: {
                show: false,
                key: '',
            },
            subscribe: [],
            userGroup: [],
            // tableData: [],
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    watch:{
        'adConfig.active':function(value){
            console.log(value);
        }
    },
    computed: {
        tableData:function (){
            // this.ad_optimize=
            //添加这一行代码是方便在更新的时候拿到index
            this.page.data.map((v,index)=>v._index=index);
            return this.page.data.filter(v=>v.type===this.adConfig.active);
        },
        searchFields: function () {
            return F.search('appAd', 'search');
        },
        showFields: function () {
            return F.show('appAd', 'show');
        },
    },

    methods: {
        changeType(active,index){
            this.adConfig.active=active;
            this.adConfig.index=index;
            let key='ad_optimize_'+active;
            //由于保存的是字符串的0，1，所以这里转换一次
            // console.log(this.adConfig);
            this.ad_optimize=!!this.adConfig.else[key];
        },
        condition: function (search) {
            this.page.search = search;
            // this.page.pageNumber = 1;
            this.search();
        },
        editElseConfig(){
            let value=JSON.stringify(this.adConfig.else['ad_else_config_'+this.adConfig.active]);
            let form=F.get('appAd','editElseConfig',{else_config:value});
            this.actionName='editElseConfig';
            form.title=`修改  ${this.adConfig.type[this.adConfig.index]['title']}`;
            this.form=form;
        },
        search: function () {
            this.$axios.get(this.baseUrl, {
                params: this.page,
            }).then(data => {
                if (!this.qq.show){
                    this.qq.show = true;
                    this.adConfig.active='android';
                }
                this.qq.key = data.data.else.app_qq_join_key;
                this.adConfig.else=data.data.else;
                this.ad_optimize=!!this.adConfig.else['ad_optimize_'+this.adConfig.active];
                // console.log(this.ad_optimize,this.adConfig.else['ad_optimize_'+this.adConfig.active]);
                this.ad_optimize_loading_ok = true;

                this.page.data=data.data.data;
            });
        },
        submit: function (data) {
            // this.con(data);
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'editAd':
                    this.$axios.put(this.baseUrl, data.data).then(adData => {
                        this.$message.success('保存成功!');
                        this.page.data.splice(this.index, 1, adData.data);
                    });
                    break;
                case 'addAd':
                    this.$axios.post(this.baseUrl, data.data).then(userData => {
                        this.$message.success('创建成功!');
                        this.page.data.unshift(userData.data);
                    });
                    break;
                case 'editQq':
                    this.$axios.put(this.singleConfigUrl, data.data).then(qqData => {
                        this.qq.key = qqData.data.app_qq_join_key;
                        this.$message.success('保存成功');
                    });
                    break;
                    case 'editElseConfig':
                        var kk='ad_else_config'+'_'+this.adConfig.active;
                        var  postData={
                            [kk]:data.data.else_config
                        };
                        try {
                            JSON.parse(data.data.else_config);
                        }catch (e) {
                            this.$message.warning('请输入正确的JSON配置');
                            return;
                        }
                        this.$axios.put(this.singleConfigUrl, postData).then(qqData => {
                            this.adConfig.else[kk] = qqData.data[kk];
                            this.$message.success('保存成功');
                        });
                        break;
            }
            this.form.show = false;
        },
        editAd: function (index) {
            let form;
            if (this.adConfig.active==='all'){
                form = F.get('appAd', 'editOld', this.page.data[index]);
            }else{
                form = F.get('appAd', 'edit', this.page.data[index]);
            }
            form.title=`修改  ${this.adConfig.type[this.adConfig.index]['title']}  配置`;
            this.actionName = 'editAd';
            this.form = form;
            this.index = index;
        },
        addAd: function () {
            let form = F.get('appAd', 'add',{type:this.adConfig.active});
            this.actionName = 'addAd';
            form.title=`添加  ${this.adConfig.type[this.adConfig.index]['title']}  配置`;
            this.form = form;
        },
        adOptimizeChange: function (val) {
            this.ad_optimize = !val;
            let title = val ? '你确认要启用广告优化吗?' : '你确认要禁用广告优化吗?';
            this.$confirm(title, '操作确认').then(() => {
                let k='ad_optimize_'+this.adConfig.active;
                this.$axios.put(this.singleConfigUrl, {[k]: val?1:0}).then(() => {
                    this.ad_optimize = val;
                    this.adConfig.else[k]=val?1:0;
                    this.$message.success('设置成功');
                });
            });
        },
        editQq: function () {
            let form = F.get('appAd', 'editQq', {app_qq_join_key: this.qq.key});
            this.actionName = 'editQq';
            this.form = form;
        },
        copyChange() {
            let dom = document.getElementById('copy-file');
            this.$confirm('你确认要上传这个文件吗?', '上传确认').then(() => {
                let file = dom.files[0];
                let form = new FormData();
                form.append('file', file);
                axiox.post('http://demo.bigemap.com/map/api/copyright.html', form).then(result => {
                    dom.value='';
                    if (!result.data||result.data.code !== 200) {
                        this.$message.warning(result.data&&result.data.message||'服务器返回数据不正确');
                        return;
                    }
                    this.$message.success('上传成功!');
                });
            }).catch(()=>{
                dom.value='';
            });
        },
        uploadCopy() {
            document.getElementById('copy-file').click();
        },
        deleteServer: function (index, id) {
            this.$confirm('你真的要删除这个订阅吗?', '操作确认').then(() => {
                this.$axios.delete(this.baseUrl, {data: {id}}).then(() => {
                    this.tableData.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        }
    }
}
</script>

<style scoped lang="less">
#copy-file {
    position: absolute;
    top: -9999px;
}
</style>
